body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, Helvetica, sans-serif;
  color: #475058;
  /* background-repeat: repeat-y;
    background-position: center center;
    overflow-x: hidden; */
}

p{
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar, .scrollBar::-webkit-scrollbar {
  width: 0.1px;
  height: 0.1px;
}

::-webkit-scrollbar-thumb, .scrollBar::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(102, 102, 102, 0.1);
}

::-webkit-scrollbar-track, .scrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: 0;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  background-color: rgba(204, 204, 204, 0.1);
}

::-webkit-scrollbar-track-piece, .scrollBar::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* ::selection {
  background: var(--accentShade3);
  color: #fff;
} */

div, a, img {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;